import fetchAllianceData from "@/common/function/fetchAllianceData";
import fetchAppSettingData from "@/common/function/fetchAppSettingData";
import { IAffiliateBonusInfo } from "@/common/type";
import { AffiliateItemData } from "@/components/Affiliate/type";
import Solana from "@/wallet/solana/Solana";
import { set } from "@project-serum/anchor/dist/cjs/utils/features";
import { createStore } from "vuex";

interface User {
  token?: string;
}
interface Message {
  message: string;
  type?: string;
}
interface Language {
  name: string;
  code: string;
  icon: string;
}
interface State {
  devLog?: any[];
  solana: Solana | undefined;
  // solana: Solana | undefined;

  rankLevelData: any[] | undefined;
  searchData: any[] | undefined;
  searchSelected: any;
  allianceData: any;
  affiliateData: any;
  rankSelected: any;
  isSearching: boolean;
  infoReward: any;
  poolInfo: any;
  sessionSolana: any;
  currentPrice: any;
  TVL: number | null;
  swapInfo: any | undefined;
  user: User;
  message: Message | undefined;
  showMessage: boolean;
  balanceTokenQuote: number;
  balanceTokenBase: number;
  balanceTokenMintStake: number;
  bannerData?: any;
  placement: string;
  tabPowering:
    | "powering"
    | "sell"
    | "loan"
    | "loan_withdraw"
    | "investment"
    | "investment_withdraw";
  affiliateBonusInfo: IAffiliateBonusInfo[];
  showSharePopup: boolean;
  showBenefitModal: boolean;
  languageData?: any;
  languages?: Language[] | undefined;
  selectedLanguage?: any;
  iconData: any;
  walletAddress: string | undefined;
  poolStakeInfo: any;
  stakeCycle: any | null;
  showTermsModal: boolean;
  showUpgradeModal: boolean;
  appSetting?: any;
  errorPopupOld: boolean;
  scrollValue: number;
  receipts: any | null;
  currentAffTree?: any;
  affTreeHistory?: AffiliateItemData[];
  treeType: "full" | "branch";
  affShowType: "board" | "tree";
  loadingClaimBonus: boolean;
  loadingClaimXBonus: boolean;
  openInviteCodeModal: boolean;
  openInviteCodeModalData: any;
  isSubAffDone: boolean;
  showConditionSharePopup: boolean;
  chartTreeData: any;
  price24h: any;
  dataChart: any;
  showingSearchResults: boolean;
  loadingApp: Record<string, boolean>;
  havingData: any;
  havingValue: number;
  claimSolxSetting: any;
  permission: any;
  alert: boolean;
  statusALert: string;
  addTokenSetting: any;
  themeMode: "dark" | "light";
  fetching: "alliance-data" | "affiliate-data";
  phantomWarning: boolean;
  poolInvestment: any;
  filteChart: "day" | "week" | "month" | "year" | "custom";
  showAnalytic: boolean;
  analyticChart: any;
  benefitData?: any;
  dataSettingFeeIn: any;
  dynamicConfigs: Record<string, any>;
  estMint: any;
  showCustomeDateChartStatistic: boolean;
  dataCustomeDateChartStatistic: any;
  openWithdrawBoost: boolean;
  dynamicData?: any;
  isTelegram: boolean;
  mintInputAmount: number | string;
  showMenuConnect: boolean;
  numOfDayHasActivity: number;
  telegramId: number;
  showNavbar?: boolean;
  showFindParentPopup?: any;
  referralData?: any;
}

const store = createStore<State>({
  state: {
    fetching: "alliance-data",
    devLog: [],
    solana: undefined,
    rankLevelData: undefined,
    allianceData: undefined,
    affiliateData: undefined,
    rankSelected: -1,
    isSearching: false,
    user: {
      token: undefined,
    },
    infoReward: {},
    poolInfo: {},
    poolStakeInfo: null,
    sessionSolana: null,
    currentPrice: null,
    TVL: null,
    swapInfo: {},
    searchData: undefined,
    searchSelected: undefined,
    message: undefined,
    showMessage: false,
    balanceTokenQuote: 0,
    balanceTokenBase: 0,
    bannerData: undefined,
    placement: "top-right",
    tabPowering: "powering",
    affiliateBonusInfo: [],
    showSharePopup: false,
    showBenefitModal: false,
    languageData: undefined,
    languages: undefined,
    selectedLanguage: undefined,
    iconData: undefined,
    walletAddress: undefined,
    stakeCycle: null,
    showTermsModal: false,
    showUpgradeModal: false,
    appSetting: undefined,
    errorPopupOld: false,
    scrollValue: 0,
    receipts: null,
    treeType: "full",
    affShowType: "board",
    loadingClaimBonus: false,
    loadingClaimXBonus: false,
    loadingApp: {
      language: true,
      appSetting: true,
    },
    openInviteCodeModal: false,
    openInviteCodeModalData: undefined,
    isSubAffDone: false,
    showConditionSharePopup: false,
    chartTreeData: undefined,
    price24h: null,
    dataChart: null,
    showingSearchResults: false,
    havingData: null,
    havingValue: 0,
    claimSolxSetting: {
      ui_active: false,
      amount_claim: 1,
    },
    permission: null,
    affTreeHistory: [],
    alert: false,
    statusALert: "",
    addTokenSetting: null,
    themeMode: "light",
    phantomWarning: false,
    poolInvestment: null,
    filteChart: "custom",
    balanceTokenMintStake: 0,
    showAnalytic: false,
    analyticChart: undefined,
    benefitData: undefined,
    dataSettingFeeIn: null,
    dynamicConfigs: {},
    estMint: null,
    showCustomeDateChartStatistic: false,
    dataCustomeDateChartStatistic: null,
    openWithdrawBoost: false,
    isTelegram: false,
    mintInputAmount: "",
    showMenuConnect: false,
    numOfDayHasActivity: 0,
    telegramId: 0,
    showNavbar: true,
    showFindParentPopup: {
      isBoosted: true,
      isMinted: true,
      isNotHasParent: true,
    },
  },
  getters: {},
  mutations: {
    setReferralData(state, data) {
      state.referralData = data;
    },
    setShowFindParentPopup(state, data) {
      state.showFindParentPopup = data;
    },
    setShowNavbar(state, data) {
      state.showNavbar = data;
    },
    setTelegramId(state, data) {
      state.telegramId = data;
    },
    setShowMenuConnect(state, data) {
      state.showMenuConnect = data;
    },
    setIsTelegram(state, data) {
      state.isTelegram = data;
    },
    setOpenWithdrawBoost(state, data) {
      state.openWithdrawBoost = data;
    },
    setDataCustomeDateChartStatistic(state, data) {
      state.dataCustomeDateChartStatistic = data;
    },
    setShowCustomeDateChartStatistic(state, data) {
      state.showCustomeDateChartStatistic = data;
    },
    setEstMint(state, data) {
      state.estMint = data;
    },
    setDataSettingFeeIne(state, data) {
      state.dataSettingFeeIn = data;
    },
    setBalanceTokenMintStake(state, data) {
      state.balanceTokenMintStake = data;
    },
    setFilteChart(state, data) {
      state.filteChart = data;
    },
    setPoolInvestment(state, data) {
      state.poolInvestment = data;
    },
    setAddTokenSetting(state, data) {
      state.addTokenSetting = data;
    },
    setAlert(state, data) {
      state.alert = data;
    },
    setStatusAlert(state, data) {
      state.statusALert = data;
    },
    setPermission(state, data) {
      state.permission = data;
    },
    setHavingValue(state, data) {
      state.havingValue = data;
    },
    setHaving(state, data) {
      state.havingData = data;
    },
    setPrice24h(state, data) {
      state.price24h = data;
    },
    setDataChart(state, data) {
      state.dataChart = data;
    },
    setShowConditionSharePopup(state, data) {
      state.showConditionSharePopup = data;
    },
    setLoadingBonus(state, data) {
      state.loadingClaimBonus = data;
    },
    setLoadingBonusx(state, data) {
      state.loadingClaimXBonus = data;
    },
    setSolana(state, data) {
      state.solana = data;
    },
    setUser(state, data) {
      state.user = data;
      if (state.user?.token) {
        fetchAllianceData();
      }
    },
    setLog(state, data) {
      state.devLog = [...(state.devLog || []), data];
    },
    setRankLevelData(state, data) {
      state.rankLevelData = data;
    },
    setRankSelected(state, data) {
      state.rankSelected = data;
    },
    setIsSearching(state, data) {
      state.isSearching = data;
    },
    //
    setInfoReward(state, data) {
      state.infoReward = data;
    },
    setInfoPool(state, data) {
      state.poolInfo = data;
    },
    setSessionSolana(state, data) {
      state.sessionSolana = data;
    },
    setCurrentPrice(state, data) {
      state.currentPrice = data;
    },
    setTVL(state, data) {
      state.TVL = data;
    },
    setSwapInfo(state, data) {
      state.swapInfo = data;
    },
    setSearchData(state, data) {
      state.searchData = data;
    },
    setAllianceData(state, data) {
      console.error("update aff data: ", data);
      state.allianceData = data;
    },
    setAffiliateData(state, data) {
      console.error(">>>>>>>>>>>>>>>setAffiliateData", data);
      state.affiliateData = data;
    },

    setSearchSelected(state, data) {
      state.searchSelected = data;
    },
    setMessage(state, data) {
      state.message = data;
    },
    showMessage(state) {
      state.showMessage = true;
    },
    closeMessage(state) {
      state.showMessage = false;
    },
    setBalanceTokenBase(state, data) {
      state.balanceTokenBase = data;
    },
    setBalanceTokenQuote(state, data) {
      state.balanceTokenQuote = data;
    },
    setBannerData(state, data) {
      state.bannerData = data;
    },
    setPlacement(state, data) {
      state.placement = data;
    },
    setPowering(state, data) {
      state.tabPowering = data;
    },
    setAffiliateBonusInfo(state, data) {
      state.affiliateBonusInfo = data;
    },
    setShowSharePopup(state, data) {
      state.showSharePopup = data;
    },
    setShowBenefitModal(state, data) {
      state.showBenefitModal = data;
    },
    setReceipt(state, data) {
      state.receipts = data;
    },
    setLanguageData(state, data) {
      if (data) {
        state.languageData = data;
        store.commit("setLoadingApp", { language: false });
        localStorage.setItem("languageData", JSON.stringify(data));
      }
    },
    setLanguages(state, data) {
      state.languages = data;
      if (Array.isArray(data) && data.length > 0) {
        let selectedLanguageFromLocal: any =
          localStorage.getItem("selectedLanguage");
        if (selectedLanguageFromLocal) {
          selectedLanguageFromLocal = JSON.parse(selectedLanguageFromLocal);
          selectedLanguageFromLocal = state.languages?.find(
            (e) => e?.code == (selectedLanguageFromLocal as any).code
          );
          if (selectedLanguageFromLocal) {
            store.commit("setSelectedLanguage", selectedLanguageFromLocal);
          } else {
            store.commit("setSelectedLanguage", data[0]);
          }
        } else {
          store.commit("setSelectedLanguage", data[0]);
        }
      } else {
        // let languageDataFromLocal = localStorage.getItem('languageData');
        // if (languageDataFromLocal) {
        //   languageDataFromLocal = JSON.parse(languageDataFromLocal);
        //   store.commit('setLanguageData', languageDataFromLocal);
        // } else {
        //   // set default
        // }
        store.commit("setSelectedLanguage", undefined);
      }
    },
    setSelectedLanguage(state, data) {
      state.selectedLanguage = data;
      if (data) {
        localStorage.setItem("selectedLanguage", JSON.stringify(data));
      }
      fetchAppSettingData(data?.code || "");
    },
    setIconData(state, data) {
      state.iconData = data;
    },
    setWalletAddress(state, data) {
      state.walletAddress = data;
    },
    setStakeCycle(state, data) {
      state.stakeCycle = data;
    },
    setPoolStakeInfo(state, data) {
      state.poolStakeInfo = data;
    },
    setShowTermsModal(state, data) {
      state.showTermsModal = data;
    },
    setShowUpgradeModal(state, data) {
      state.showUpgradeModal = data;
    },
    setAppSetting(state, data) {
      state.appSetting = data;
      if (data) {
        store.commit("setLoadingApp", { appSetting: false });
      }
      const timesFromLocalStorage = localStorage.getItem("times-upgraded");
      const isShowUpgradeModal =
        data?.maintain_noti.enable &&
        data?.maintain_noti.times + "" !== `${timesFromLocalStorage}`;
      store.commit("setShowUpgradeModal", isShowUpgradeModal);
    },
    setErrorPopupOld(state, data) {
      state.errorPopupOld = data;
    },
    setScrollValue(state, data) {
      state.scrollValue = data ?? 0;
    },
    setCurrentAffTree(state, data) {
      state.currentAffTree = data;
    },
    setTreeType(state, data) {
      state.treeType = data;
    },
    setAffShowType(state, data) {
      state.affShowType = data;
    },
    setOpenInviteCodeModal(state, data) {
      state.openInviteCodeModal = data;
    },
    setOpenInviteCodeModalData(state, data) {
      state.openInviteCodeModalData = data;
    },
    setIsSubAffDone(state, data) {
      state.isSubAffDone = data;
    },
    setChartTreeData(state, data) {
      state.chartTreeData = data;
    },
    setShowingSearchResults(state, data) {
      state.showingSearchResults = data;
    },
    setLoadingApp(state, data) {
      state.loadingApp = { ...state.loadingApp, ...data };
    },
    setAffTreeHistory(state, data) {
      state.affTreeHistory = data;
    },
    addFirstElementOffAffTreeHistory(state, data) {
      const current = state.affTreeHistory;
      current.unshift(data);
      state.affTreeHistory = current;
    },
    removeFirstElementOffAffTreeHistory(state, data) {
      const current = state.affTreeHistory;
      current.shift();
      state.affTreeHistory = current;
    },

    replaceFirstElementOffAffTreeHistory(state, data) {
      const current = state.affTreeHistory;
      current.shift();
      current.unshift(data);
      state.affTreeHistory = current;
    },
    setThemeMode(state, data) {
      state.themeMode = data;
      const body = document.querySelector("body");
      if (data == "light") {
        body?.classList?.remove("dark");
        body?.classList?.add("light");
      } else if (data == "dark") {
        body?.classList?.remove("light");
        body?.classList?.add("dark");
      }
      localStorage.setItem("theme-mode", `${data}`);
    },
    setFetching(state, data) {
      state.fetching = data;
    },
    setPhantomWarning(state, data) {
      state.phantomWarning = data;
    },
    setShowAnalytic(state, data) {
      state.showAnalytic = data;
    },
    setAnalyticChart(state, data) {
      state.analyticChart = data;
    },
    setBenefitData(state, data) {
      state.benefitData = data;
    },
    setDynamicConfigs(state, data) {
      state.dynamicConfigs = data;
    },
    setDynamicData(state, data) {
      state.dynamicData = data;
    },
    setMintInputAmount(state, data) {
      state.mintInputAmount = data;
    },
    setNumOfDayHasActivity(state, data) {
      state.numOfDayHasActivity = data;
    },
  },
  actions: {},
  modules: {},
});

export default store;
