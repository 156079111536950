<!-- eslint-disable no-useless-escape -->
<template>
  <div class="home_link_container">
    <section-title
      :title="translation('link_Your_Referral_Link')"
      class="self-start"
    >
    </section-title>
    <card-container
      class="override_card_content"
      bg-color="purple-pizzazz"
      :padding-top="0"
      :padding-right="0"
      :padding-bottom="0"
      :padding-left="0"
      :displacement-level="0"
    >
      <div class="card_content">
        <section class="aff_info_section">
          <div
            class="share_g share_g_new"
            v-if="myReferralData?.status == true"
          >
            <div
              v-if="true && false"
              class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 border_2 rounded-[12px] p-4 bg_dark_light_most bg-[#FFD597]"
            >
              <div
                class="lg:pr-4 lg:border-r border-b-[1px] lg:border-b-[0px] pb-4 lg:pb-0 border-black border_dark flex flex-col gap-4"
              >
                <div
                  class="bg_dark_light_most bg-[#ffd597] rounded-[12px] text-black text_dart"
                >
                  <div class="flex items-center justify-between">
                    <div
                      class="!text-black text_dark whitespace-nowrap next_level_condition"
                    >
                      {{ translation("next_level_condition") }}
                    </div>
                    <RoundButton
                      @click="
                        () => {
                          store.commit('setShowBenefitModal', true);
                        }
                      "
                      custom-padding="0px"
                      active
                      class="min-w-[0px] !border-[1px]"
                    >
                      <div class="flex items-center gap-1.5 py-1.5 px-2">
                        <span class="link_benefit_button">{{
                          translation("link_benefit_button")
                        }}</span
                        ><img
                          :src="getIcon('left_arrow_button')"
                          class="block h-2.5 w-1.5"
                          alt=""
                        />
                      </div>
                    </RoundButton>
                  </div>

                  <div
                    class="border_1 rounded-full overflow-hidden w-full my-3"
                  >
                    <v-progress-linear
                      model-value="50"
                      color="amber"
                      height="8"
                    ></v-progress-linear>
                  </div>
                  <div class="flex flex-col gap-3">
                    <ListItemCheck
                      :content="
                        translation('Have 2 members depositing 10 SOL.')
                      "
                      :process="'1/2'"
                    />
                    <ListItemCheck
                      :content="
                        translation('The team performs 20 SOL deposits.')
                      "
                      :process="'Complete'"
                      is-done
                    />
                    <div class="container_text_bottom_button">
                      <img
                        :src="getIcon('red_tooltip')"
                        alt=""
                        class="block w-6 h-6 relative top-[-2px]"
                      />
                      <!-- <span
                        >{{ translation("link_rank_explainoooooooooo") }}
                      </span> -->
                      <KatexTooltip tooltip-key="link_rank_explainmmmm" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="min-h-[420px] flex flex-col gap-2">
                <div class="flex items-center justify-between">
                  <div class="!text-black text_dark next_level_condition">
                    {{ translation("link_pnl_chart") }}
                  </div>
                  <div
                    @click="
                      () => {
                        store.commit('setShowAnalytic', true);
                      }
                    "
                    class="analytic_button flex items-center gap-1.5 py-1.5 px-2 cursor-pointer hover:translate-x-1 duration-200"
                  >
                    <span class="link_benefit_button text_dark">{{
                      translation("link_analytic")
                    }}</span
                    ><img
                      :src="
                        getIcon(
                          isDark()
                            ? 'left_arrow_button_white'
                            : 'left_arrow_button'
                        )
                      "
                      class="block h-2.5 w-1.5"
                      alt=""
                    />
                  </div>
                </div>
                <div class="flex-1 min-h-[354px]">
                  <TradingChart />
                </div>
                <div class="flex items-center gap-2 pt-2 pl-3">
                  <RoundButton
                    @click="
                      () => {
                        selectedTimeRange = e.value;
                      }
                    "
                    :active="e.value === selectedTimeRange"
                    custom-padding="8px"
                    v-for="(e, i) in timeRange"
                    :key="i"
                    class="time_range_label !min-w-0 !border-[1px]"
                  >
                    <span
                      class="time_range_label"
                      :style="{
                        color:
                          e.value === selectedTimeRange
                            ? '#000'
                            : isDark()
                            ? '#fff'
                            : '#000',
                      }"
                      >{{ e.label }}</span
                    >
                  </RoundButton>
                </div>
              </div>
            </div>
            <!-- <div class="section_1 flex items-center w-full justify-between">
              <div class="flex flex-col"></div>
            </div> -->
            <div class="flex flex-col gap-2 w-full">
              <div class="line !justify-between w-full">
                <div class="line_title">
                  <KatexTooltip value="link_Your_Rank" />
                </div>
                <a
                  class="rank_navigate"
                  :href="myReferralData?.knowledge_url"
                  target="_blank"
                >
                  <div class="value text_decoration !text-[#a366ff]">
                    {{
                      translation(
                        createKey(
                          createKeyByNumber(
                            store.state.affiliateData?.ranking ?? 0,
                            "_r"
                          ),
                          "_rank_name"
                        )
                      )
                    }}
                  </div>
                </a>
              </div>
              <!-- <ListItemCheck
                :align="'center'"
                :icon="getIcon('green_check')"
                :content="
                  translation(
                    'Lorem ipsum dolor sit amet consectetur. (Quyền lợi)'
                  )
                "
              />
              <ListItemCheck
                :align="'center'"
                :icon="getIcon('green_check')"
                :content="
                  translation(
                    'Lorem ipsum dolor sit amet consectetur. (Quyền lợi)'
                  )
                "
              /> -->
            </div>
            <div class="w-full flex flex-col gap-3">
              <div class="line w-full !justify-between">
                <div class="line_title">
                  <!-- {{ translation("link_Your_Referral_ID") }} -->
                  <KatexTooltip value="link_Your_Referral_ID" />
                </div>
                <div class="rank_value_group">
                  <div class="value">
                    {{ myReferralData?.aff_id ?? "--" }}
                  </div>
                  <CopyText :value="myReferralData?.aff_id ?? '--'">
                    <v-img
                      loading="lazy"
                      :src="
                        getIcon(
                          `white_copy${
                            store.state.themeMode == 'light' ? '' : '_dark'
                          }`
                        )
                      "
                      :width="24"
                      aspect-ratio="1"
                    ></v-img>
                  </CopyText>
                </div>
              </div>
              <!-- <div class="line w-full !justify-between">
                <div class="line_title">
                  {{ translation("link_Your_Referral_link") }}
                </div>
                <div class="rank_value_group">
                  <div class="value">
                    {{
                      myReferralData?.aff_link?.slice(0, 11) +
                        "..." +
                        myReferralData?.aff_link?.slice(-3) ?? "--"
                    }}
                  </div>
                  <CopyText :value="myReferralData?.aff_link ?? '--'">
                    <v-img
                      loading="lazy"
                      :src="
                        getIcon(
                          `white_copy${
                            store.state.themeMode == 'light' ? '' : '_dark'
                          }`
                        )
                      "
                      :width="24"
                      aspect-ratio="1"
                    ></v-img>
                  </CopyText>
                </div>
              </div> -->
            </div>
            <!-- <div
              v-if="myReferralData?.status == false"
              class="w-full flex flex-col gap-3"
            >
              <div class="line w-full !justify-between">
                <div class="line_title">
                  {{ translation("link_missing") }}
                </div>
                <div class="rank_value_group">
                  <div class="value">
                    <FormatterNumber
                      :value="
                        Number(myReferralData?.minimum_mint || 0) -
                        Number(myReferralData?.your_mint || 0)
                      "
                      :unit="translation('_sol_u')"
                    />
                  </div>
                </div>
              </div>
              <div class="missing_message">
                <span>{{ translation("missing_message_before") }}</span>
                <FormatterNumber :value="myReferralData?.minimum_mint" />
                <span>{{ translation("missing_message_after") }}</span>
              </div>
            </div> -->

            <!-- <div
              v-if="myReferralData?.status == false"
              class="w-full flex items-center justify-between gap-3"
            >
              <PrimaryButton @click="goMining" style="width: 100%">{{
                translation("Go_Minting")
              }}</PrimaryButton>
            </div> -->

            <div class="w-full flex items-center justify-between gap-3">
              <PrimaryButton
                class="flex-1"
                @click="showPopupShare"
                :disabled="!myReferralData"
                >{{ translation("link_Share") }}</PrimaryButton
              >
              <PrimaryButton
                @click="() => (showQR = !showQR)"
                custom-padding="10px"
                :custom-background="
                  store.state.themeMode == 'dark' ? undefined : '#F5FFCE'
                "
                class="!aspect-square w-[50px]"
              >
                <img
                  :src="getIcon(store.state.themeMode == 'dark' ? 'qr' : 'qr')"
                  alt=""
                  class="block w-6 h-6"
                />
              </PrimaryButton>
            </div>
          </div>
          <div
            class="!max-w-[650px] mx-auto w-full rounded-[20px]"
            v-if="myReferralData"
          >
            <TwoTabAnalytic
              :field_1="(+store.state.affiliateData?.affiliateGroup || +(store.state.affiliateData as any)?.boostingGroup)&&translation('link_sol_contribution')"
              :field_2="(+store.state.affiliateData?.affiliateGroup || +(store.state.affiliateData as any)?.boostingGroup)&&translation('link_solx_boosting')"
              :value_1="store.state.affiliateData?.affiliateGroup ?? 0"
              :value_2="(store.state.affiliateData as any)?.boostingGroup ?? 0"
              two-colum-ignore
            >
              <template #top>
                <div
                  class="w-full flex flex-col gap-1 !border-b border-black border_dark pb-2 pt-3"
                >
                  <div class="line">
                    <div class="line_title">
                      <KatexTooltip value="link_Total_Referrals" />
                    </div>
                    <div class="flex items-center gap-1.5">
                      <div class="value">
                        <FormatterNumber
                          disable-tooltip
                          :value="store.state.affiliateData?.totalReferral ?? 0"
                        />
                      </div>
                      <img
                        v-if="store.state.affiliateData?.totalReferral"
                        :src="getIcon(isDark() ? 'referral_white' : 'referral')"
                        alt=""
                        class="block w-6 h-6 relative top-[-1px]"
                      />
                    </div>
                  </div>
                  <div class="line w-full !justify-between">
                    <div class="line_title">
                      <KatexTooltip value="link_Your_parent_Referral_ID" />
                    </div>
                    <div class="rank_value_group">
                      <div class="value">
                        {{ (myReferralData as any)?.parent_aff_id || "--" }}
                      </div>
                    </div>
                  </div>
                  <div class="line w-full !justify-between">
                    <div class="line_title">
                      <KatexTooltip value="link_Your_parent_Referral_wallet" />
                    </div>
                    <div class="rank_value_group">
                      <a
                        v-if="(myReferralData as any)?.parent_wallet_address"
                        class="rank_navigate"
                        :href="`${translation('solscan_url_account') ??
                        'https://solscan.io/account/'}${(myReferralData as any)?.parent_wallet_address}`
                        "
                        target="_blank"
                      >
                        <div class="value text_decoration">
                          {{
                            compactWalletAddress(
                              (myReferralData as any)?.parent_wallet_address ||
                                "--"
                            )
                          }}
                        </div>
                      </a>
                      <div
                        v-if="!(myReferralData as any)?.parent_wallet_address"
                        class="value"
                      >
                        {{
                          compactWalletAddress(
                            (myReferralData as any)?.parent_wallet_address ||
                              "--"
                          )
                        }}
                      </div>
                      <!-- <CopyText
                        :value="(myReferralData as any)?.parent_wallet_address ?? '--'"
                      >
                        <v-img
                          loading="lazy"
                          :src="
                            getIcon(
                              `white_copy${
                                store.state.themeMode == 'light' ? '' : '_dark'
                              }`
                            )
                          "
                          :width="24"
                          aspect-ratio="1"
                        ></v-img>
                      </CopyText> -->
                    </div>
                  </div>
                </div>
              </template>

              <IntroductionGroup
                v-if="!(myReferralData as any)?.parent_aff_id"
                prefix="enter_referral_code_"
                class="pt-6 pb-4 px-4"
                :on-click-button="
                  (data) => {
                    store.commit('setOpenInviteCodeModal', true);
                  }
                "
              ></IntroductionGroup>
              <IntroductionGroup
                v-if="(myReferralData as any)?.parent_aff_id && !myReferralData?.aff_id"
                prefix="let_contribute_"
                class="pt-6 pb-4 px-4"
                :on-click-button="
                  (data) => {
                    goMining();
                  }
                "
                desSlot
              >
                <template #desSlot>
                  <span class="missing_message text-center">
                    <KatexTooltip value="minted_message_before" />
                    <FormatterNumber
                      :value="Number(myReferralData?.minimum_mint)"
                      :unit="translation('_sol_u')"
                    />
                    <KatexTooltip value="minted_message_after" />
                    <KatexTooltip value="missing_message_before" />
                    <span
                      class="text-[#2069ff] cursor-pointer"
                      @click="
                        () => {
                          store.commit(
                            'setMintInputAmount',
                            myReferralData?.minimum_mint
                          );
                          goMining();
                        }
                      "
                    >
                      <FormatterNumber
                        :value="
                          Number(myReferralData?.minimum_mint) -
                          Number(myReferralData?.your_mint)
                        "
                        :unit="translation('_sol_u')"
                      />
                    </span>
                    <KatexTooltip value="missing_message_after" />
                  </span>
                </template>
              </IntroductionGroup>
              <IntroductionGroup
                v-if="
                  (myReferralData as any)?.parent_aff_id && myReferralData?.aff_id &&
                  !store.state.affiliateData?.totalReferral
                "
                prefix="invite_friend_"
                class="pt-6 pb-4 px-4"
              ></IntroductionGroup>
              <IntroductionGroup
                v-if="
                (myReferralData as any)?.parent_aff_id &&  myReferralData?.aff_id &&
                  store.state.affiliateData?.totalReferral > 0 &&
                  (!myReferralData?.solReward?.earned ||
                    +myReferralData?.solReward?.earned <= 0) &&
                  (!myReferralData?.solxReward?.earned ||
                    +myReferralData?.solxReward?.earned <= 0)
                "
                prefix="invite_more_friend_"
                class="pt-6 pb-4 px-4"
              ></IntroductionGroup>
              <div
                class="p-2.5 xl:p-4 pt-0"
                v-if="
                  (myReferralData?.solReward?.earned &&
                    +myReferralData?.solReward?.earned > 0) ||
                  (myReferralData?.solxReward?.earned &&
                    +myReferralData?.solxReward?.earned > 0)
                "
              >
                <div class="flex items-center gap-4 py-3">
                  <RoundButton
                    :active="rewardTab == 'sol'"
                    @click="() => (rewardTab = 'sol')"
                    >{{ translation("link_sol_reward") }}
                  </RoundButton>
                  <RoundButton
                    :active="rewardTab == 'solx'"
                    @click="() => (rewardTab = 'solx')"
                    >{{ translation("link_solx_reward") }}
                  </RoundButton>
                </div>
                <div>
                  <div
                    class="receive_reward_block"
                    v-if="
                      rewardTab == 'sol' &&
                      myReferralData?.solReward?.earned &&
                      +myReferralData?.solReward?.earned > 0
                    "
                  >
                    <div id="claim_sol_box"></div>
                    <div class="info_reward_g">
                      <div class="line">
                        <div class="line_title">
                          <KatexTooltip value="link_Total_Referral_Earned" />
                        </div>
                        <div class="value">
                          <FormatterNumber
                            :use-change-animation="true"
                            :value="myReferralData?.solReward?.earned ?? 0.0"
                            :unit="translation('_sol_u')"
                          />
                        </div>
                      </div>
                      <div class="line">
                        <div class="line_title">
                          <KatexTooltip value="link_Total_Referral_Withdrawn" />
                        </div>
                        <div class="value">
                          <FormatterNumber
                            :use-change-animation="true"
                            :value="myReferralData?.solReward?.withdrawn ?? 0.0"
                            :unit="translation('_sol_u')"
                          />
                        </div>
                      </div>
                      <div class="line">
                        <div class="line_title">
                          <KatexTooltip value="link_Your_Power" />
                        </div>
                        <div class="value">
                          <FormatterNumber
                            :use-change-animation="true"
                            :value="
                              myReferralData?.solReward?.current_earned ?? 0
                            "
                            :unit="translation('_sol_u')"
                          />
                        </div>
                      </div>
                      <div class="horizontal_divider"></div>
                      <div class="line_title_total">
                        <KatexTooltip value="link_Referral_Earned_Available" />
                      </div>
                      <div class="line total">
                        <div class="line_title">
                          <KatexTooltip
                            value="link_Referral_Earned_Available_"
                          />
                        </div>
                        <div class="value_total">
                          <span class="text-[#A366FF]">
                            <FormatterNumber
                              :use-change-animation="true"
                              :value="myReferralData?.solReward?.available ?? 0"
                            />
                          </span>
                          <span class="ml-1">{{
                            translation("link_SOL")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        statusCountdownFeture &&
                        (!store.state.permission ||
                          (store.state.permission?.success == true
                            ? !store.state.permission?.data[
                                'claim-sol-aff-bonus'
                              ]
                            : true))
                      "
                      class="flex justify-start"
                    >
                      <div
                        class="flex justify-start w-full text-[#FF5153] text-base"
                      >
                        {{ translation("content_countdown_feature") }}
                        {{ statusCountdownFeture }}
                      </div>
                    </div>
                    <div
                      v-if="
                        Number(myReferralData?.solReward?.deposit_more_amount) >
                          0 &&
                        Number(myReferralData?.solReward?.available) >=
                          Number(myReferralData?.solReward?.min_reward_withdraw)
                      "
                      class="container_text_bottom_button"
                    >
                      <img
                        :src="getIcon('red_tooltip')"
                        alt=""
                        class="block w-6 h-6 relative top-[-2px]"
                      />
                      <span
                        >{{ translation("ref_power_up") }}
                        <FormatterNumber
                          :value="
                            Number(
                              myReferralData?.solReward?.deposit_more_amount
                            )
                          "
                        />
                        {{ translation("SOL_to_claim_all_your_reward") }}
                      </span>
                    </div>
                    <div
                      v-if="
                        Number(myReferralData?.solReward?.available) <
                        Number(myReferralData?.solReward?.min_reward_withdraw)
                      "
                      class="container_text_bottom_button"
                    >
                      <img
                        :src="getIcon('red_tooltip')"
                        alt=""
                        class="block w-6 h-6 relative top-[-2px]"
                      />
                      <span
                        >{{ translation("min_reward_withdraw") }}
                        <FormatterNumber
                          :value="
                            Number(
                              myReferralData?.solReward?.min_reward_withdraw
                            )
                          "
                        />
                        {{ translation("min_reward_withdraw_after") }}
                      </span>
                    </div>
                    <PrimaryButton
                      class="override_button"
                      v-if="myReferralData"
                      :is-transaction="true"
                      :loading="loading"
                      @click="() => claimHandler()"
                      :disabled="
                        Number(myReferralData?.solReward?.available) <
                          Number(
                            myReferralData?.solReward?.min_reward_withdraw
                          ) ||
                        !(Number(myReferralData?.solReward?.available) > 0) ||
                        isLatterDate(
                          new Date(myReferralData?.solReward?.unlock_time),
                          trigger
                        ) ||
                        !store.state.permission ||
                        (store.state.permission?.success == true
                          ? !store.state.permission?.data['claim-sol-aff-bonus']
                          : true)
                      "
                    >
                      <div
                        class="flex items-center gap-1"
                        v-if="
                          !store.state.permission ||
                          (store.state.permission?.success == true
                            ? !store.state.permission?.data[
                                'claim-sol-aff-bonus'
                              ]
                            : true)
                        "
                      >
                        <img
                          :src="getIcon('lock')"
                          alt=""
                          class="w-[18px] h-[18px] relative top-[-2px]"
                        />
                        <span>{{ translation("link_claim_coming_soon") }}</span>
                      </div>
                      <vue-countdown
                        @end="onCountdownEnd"
                        :time="
                          new Date(
                            myReferralData?.solReward?.unlock_time
                          ).getTime() - new Date().getTime()
                        "
                        v-slot="{ days, hours, minutes, seconds }"
                        v-if="
                          store.state.permission &&
                          (store.state.permission?.success == true
                            ? store.state.permission?.data[
                                'claim-sol-aff-bonus'
                              ]
                            : false)
                        "
                      >
                        {{
                          isLatterDate(
                            new Date(myReferralData?.solReward?.unlock_time),
                            trigger
                          ) && Number(myReferralData?.solReward?.available) > 0
                            ? `${days}:${hours}:${minutes}:${seconds}`
                            : translation("link_Claim_aff")
                        }}
                      </vue-countdown>
                    </PrimaryButton>
                    <div
                      class="text-center"
                      v-if="
                        +myReferralData?.solReward?.earned ==
                        +myReferralData?.solReward?.withdrawn
                      "
                    >
                      <span
                        class="text-[#636363] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_all_sol_reward_was_claimed" />
                      </span>
                    </div>
                  </div>
                  <div
                    class="receive_reward_block"
                    v-if="
                      rewardTab == 'sol' &&
                      (!myReferralData?.solReward?.earned ||
                        +myReferralData?.solReward?.earned <= 0)
                    "
                  >
                    <div class="text-center">
                      <span
                        class="text-[#636363] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_SOL_reward_before" />
                      </span>
                      <span
                        class="text-[#2069ff] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_SOL_reward" />
                      </span>
                      <span
                        class="text-[#636363] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_SOL_reward_after" />
                      </span>
                    </div>
                  </div>
                  <div
                    class="receive_reward_block"
                    v-if="
                      rewardTab == 'solx' &&
                      (!myReferralData?.solxReward?.earned ||
                        +myReferralData?.solxReward?.earned <= 0)
                    "
                  >
                    <div class="text-center">
                      <span
                        class="text-[#636363] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_SOLX_reward_before" />
                      </span>
                      <span
                        class="text-[#2069ff] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_SOLX_reward" />
                      </span>
                      <span
                        class="text-[#636363] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_SOLX_reward_after" />
                      </span>
                    </div>
                  </div>
                  <div
                    class="receive_reward_block"
                    v-if="
                      rewardTab == 'solx' &&
                      myReferralData?.solxReward?.earned &&
                      +myReferralData?.solxReward?.earned > 0
                    "
                  >
                    <div id="claim_solx_box"></div>
                    <div class="info_reward_g">
                      <div class="line">
                        <div class="line_title">
                          <KatexTooltip value="link_Total_Referral_Earned_x" />
                        </div>
                        <div class="value">
                          <FormatterNumber
                            :use-change-animation="true"
                            :value="myReferralData?.solxReward?.earned ?? 0"
                            :unit="translation('_solx_u')"
                          />
                        </div>
                      </div>
                      <div class="line">
                        <div class="line_title">
                          <KatexTooltip
                            value="link_Total_Referral_Withdrawn_x"
                          />
                        </div>
                        <div class="value">
                          <FormatterNumber
                            :use-change-animation="true"
                            :value="myReferralData?.solxReward?.withdrawn ?? 0"
                            :unit="translation('_solx_u')"
                          />
                        </div>
                      </div>
                      <div class="line">
                        <div class="line_title">
                          <KatexTooltip value="link_Your_Stake" />
                        </div>
                        <div class="value">
                          <FormatterNumber
                            :use-change-animation="true"
                            :value="
                              myReferralData?.solxReward?.current_earned ?? 0
                            "
                            :unit="translation('_solx_u')"
                          />
                        </div>
                      </div>
                      <div class="horizontal_divider"></div>
                      <div class="line_title_total">
                        <KatexTooltip
                          value="link_Referral_Earned_Available_x"
                        />
                      </div>
                      <div class="line total">
                        <div class="line_title">
                          <KatexTooltip
                            value="link_Referral_Earned_Available__x"
                          />
                        </div>
                        <div class="value_total">
                          <span class="text-[#A366FF]">
                            <FormatterNumber
                              :use-change-animation="true"
                              :value="
                                myReferralData?.solxReward?.available ?? 0
                              "
                            />
                          </span>
                          <span class="ml-1">{{
                            translation("link_SOLX")
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        statusCountdownFeture &&
                        (!store.state.permission ||
                          (store.state.permission?.success == true
                            ? !store.state.permission?.data[
                                'claim-solx-aff-bonus'
                              ]
                            : true))
                      "
                      class="flex justify-start"
                    >
                      <div class="flex justify-start w-full text-red-500">
                        {{ translation("content_countdown_feature_x") }}
                        {{ statusCountdownFeture }}
                      </div>
                    </div>
                    <div
                      v-if="
                        Number(
                          myReferralData?.solxReward?.deposit_more_amount
                        ) > 0 &&
                        Number(myReferralData?.solxReward?.available) >=
                          Number(
                            myReferralData?.solxReward?.min_reward_withdraw
                          )
                      "
                      class="container_text_bottom_button"
                    >
                      <img
                        :src="getIcon('red_tooltip')"
                        alt=""
                        class="block w-6 h-6 relative top-[-2px]"
                      />
                      <span
                        >{{ translation("ref_stake") }}
                        <FormatterNumber
                          :value="
                            Number(
                              myReferralData?.solxReward?.deposit_more_amount
                            )
                          "
                        />
                        {{ translation("SOLX_to_claim_all_your_reward") }}
                      </span>
                    </div>
                    <div
                      v-if="
                        Number(myReferralData?.solxReward?.available) <
                        Number(myReferralData?.solxReward?.min_reward_withdraw)
                      "
                      class="container_text_bottom_button"
                    >
                      <img
                        :src="getIcon('red_tooltip')"
                        alt=""
                        class="block w-6 h-6 relative top-[-2px]"
                      />
                      <span
                        >{{ translation("min_reward_withdraw_x") }}
                        <FormatterNumber
                          :value="
                            Number(
                              myReferralData?.solxReward?.min_reward_withdraw
                            )
                          "
                        />
                        {{ translation("min_reward_withdraw_x_after") }}
                      </span>
                    </div>
                    <PrimaryButton
                      class="override_button"
                      v-if="myReferralData"
                      :loading="loadingX"
                      :is-transaction="true"
                      @click="() => claimXHandler()"
                      :disabled="
                        Number(myReferralData?.solxReward?.available) <
                          Number(
                            myReferralData?.solxReward?.min_reward_withdraw
                          ) ||
                        (Number(myReferralData?.solxReward?.available) > 0
                          ? false
                          : true) ||
                        isLatterDate(
                          new Date(myReferralData?.solxReward?.unlock_time),
                          trigger
                        ) ||
                        !store.state.permission ||
                        (store.state.permission?.success == true
                          ? !store.state.permission?.data[
                              'claim-solx-aff-bonus'
                            ]
                          : true)
                      "
                    >
                      <div
                        class="flex items-center gap-1"
                        v-if="
                          !store.state.permission ||
                          (store.state.permission?.success == true
                            ? !store.state.permission?.data[
                                'claim-solx-aff-bonus'
                              ]
                            : true)
                        "
                      >
                        <img
                          :src="getIcon('lock')"
                          alt=""
                          class="w-[18px] h-[18px] relative top-[-2px]"
                        />
                        <span>{{
                          translation("link_claimx_coming_soon")
                        }}</span>
                      </div>
                      <vue-countdown
                        @end="onCountdownEnd"
                        :time="
                          new Date(
                            myReferralData?.solxReward?.unlock_time
                          ).getTime() - new Date().getTime()
                        "
                        v-slot="{ days, hours, minutes, seconds }"
                        v-if="
                          store.state.permission &&
                          store.state.permission?.success == true
                            ? store.state.permission?.data[
                                'claim-solx-aff-bonus'
                              ]
                            : false
                        "
                      >
                        {{
                          isLatterDate(
                            new Date(myReferralData?.solxReward?.unlock_time),
                            trigger
                          ) && Number(myReferralData?.solxReward?.available) > 0
                            ? `${days}:${hours}:${minutes}:${seconds}`
                            : translation("link_ClaimX_aff")
                        }}
                      </vue-countdown>
                    </PrimaryButton>
                    <div
                      class="text-center"
                      v-if="
                        +myReferralData?.solxReward?.earned ==
                        +myReferralData?.solxReward?.withdrawn
                      "
                    >
                      <span
                        class="text-[#636363] text-base font-normal font-['GT Walsheim'] leading-7"
                      >
                        <KatexTooltip value="_all_solx_reward_was_claimed" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </TwoTabAnalytic>
          </div>
          <div
            class="info_reward_g info_reward_g_new !max-w-[650px] mx-auto w-full"
          >
            <div class="reward_title">
              <span>{{
                translation("link_Referral_rewards_for_SolX_community")
              }}</span>
              <br v-if="useResponsive().isMobileScreen" />
              {{ translation("link_mining_and") }}
              <br v-if="!useResponsive().isMobileScreen" />
              {{ translation("link_development_participants_") }}
            </div>
            <div class="detail_g_container">
              <div class="reward_title">
                {{ translation("link_You_would_receive_") }}
              </div>
              <ul class="detail_block ml-[20px]">
                <li
                  class="detail_item"
                  v-for="(item, index) in handleArray(rewardTab == 'sol' ? (affiliateBonusInfo as any).sol : (affiliateBonusInfo as any).solx)"
                  :key="index"
                >
                  <b>{{ item.bonus }}%</b>
                  {{ translation("link_from_each_level") }}
                  <b>level {{ item.level }}</b>
                  {{ translation("link_referral_deposits_") }}
                </li>
              </ul>
            </div>

            <div class="reward_title">
              {{
                translation(
                  "link_Deposit_at_least_once_to_activate_Referral_Rewards"
                )
              }}
            </div>
          </div>
        </section>
      </div>
    </card-container>
  </div>

  <n-modal
    v-model:show="store.state.showBenefitModal"
    preset="card"
    :title="translation('link_benefit_nodal')"
    :bordered="false"
    :style="{
      width: 'calc(100vw - 32px)',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: '650px !important',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
  >
    <!-- <ConfirmWithdrawFinal /> -->
  </n-modal>
  <n-modal
    v-model:show="store.state.showAnalytic"
    preset="card"
    :title="translation('link_analytic_nodal')"
    :bordered="false"
    :style="{
      width: 'calc(100vw - 32px)',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: '960px !important',
      backgroundColor: isDark() ? '#1E1E1E' : '#fff',
    }"
  >
    <AnalyticComponent />
  </n-modal>
  <n-modal
    v-model:show="store.state.showSharePopup"
    preset="card"
    :title="translation('link_Share_Affiliate')"
    :bordered="false"
    :style="{
      width: '400px',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: 'calc(100vw - 32px)',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
  >
    <div class="link_section">
      <div class="link_g">
        <input disabled :value="myReferralData?.aff_link ?? '--'" class="" />
        <CopyText :value="myReferralData?.aff_link ?? '--'">
          <img
            loading="lazy"
            class="copy_aff_icon"
            :src="getIcon(isDark() ? 'copy_icon_white' : 'copy_icon')"
            alt=""
          />
        </CopyText>
      </div>
      <div class="link_g_des">
        {{ translation("link_Copy_and_share_your_referral_link_with_friends") }}
      </div>
    </div>
    <div>
      <div class="social_title">
        {{ translation("link_Social") }}
      </div>
      <div class="social_board">
        <a
          :href="e.url"
          target="b_lank"
          v-for="(e, i) in myReferralData?.social"
          :key="i"
          class="social_item"
        >
          <n-avatar :src="remoteImage(e.image)" :size="44" round> </n-avatar>
          <div class="social_name">
            {{ e.name }}
          </div>
        </a>
      </div>
    </div>
  </n-modal>
  <n-modal
    v-model:show="showQR"
    preset="card"
    :title="translation('link_qr_code')"
    :bordered="false"
    :style="{
      width: '400px',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: 'calc(100vw - 32px)',
      backgroundColor: isDark() ? '#2B2B2B' : '#fff',
    }"
  >
    <div class="link_section flex flex-col items-center gap-3 pb-6">
      <div class="link_g_qr_des self-start">
        {{ translation("link_qr_des") }}
      </div>
      <div class="border-[1px] border-black border_dark !h-fit">
        <n-qr-code
          color="white"
          background-color="#2B2B2B"
          class="!p-0 !m-0 !block"
          :size="150"
          :value="myReferralData?.aff_link ?? '--'"
          :icon-src="getIcon('solx_logo')"
          error-correction-level="H"
        />
      </div>
    </div>
  </n-modal>
  <!--  -->
  <n-modal
    v-model:show="store.state.showConditionSharePopup"
    preset="card"
    :title="translation('Affiliate')"
    :bordered="false"
    :style="{
      width: '400px',
      borderRadius: '24px',
      border: '2px black solid',
      maxWidth: 'calc(100vw - 32px)',
    }"
  >
    <div class="link_section link_section_popup_required">
      <div class="message_warning_share">
        {{ translation("You_need_to_minting") }}
        <b class="amount_min_mint">
          <FormatterNumber
            :value="
              Number(myReferralData?.minimum_mint || 0) -
              Number(myReferralData?.your_mint || 0)
            "
          />
        </b>

        {{ translation("SOL_to_get_refferal_link") }}
      </div>
      <PrimaryButton @click="goMining" style="width: 100%">{{
        translation("Go_Minting")
      }}</PrimaryButton>
    </div>
  </n-modal>
</template>

<script setup lang="ts">
import CardContainer from "./CardContainer.vue";
import KatexTooltip from "./KatexTooltip.vue";
import SectionTitle from "./SectionTitle.vue";
import PrimaryButton from "./PrimaryButton.vue";
import TradingChart from "./TradingChart.vue";
import BenefitModal from "./BenefitModal.vue";
import CopyText from "./CopyText.vue";
import { NModal, NAvatar, NQrCode } from "naive-ui";
import { getMyReferral, claimReferral } from "@/graphql/query/referralQuery";
import {
  provideApolloClient,
  useQuery,
  UseQueryReturn,
} from "@vue/apollo-composable";
import { userClient } from "@/graphql/client/user-client";
import {
  ref,
  watch,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  computed,
} from "vue";
import {
  IAffiliateBonusInfo,
  IAllianceGroup,
  IMyReferral,
} from "@/common/type";
import { useStore } from "vuex";
import { Emitter } from "mitt";
import useSolana from "@/hook/useSolana";
import { isDeviceLaptop } from "@/wallet/solana/Solana";
import useTextMessage from "@/hook/useTextMessage";
import { useMessage } from "naive-ui";
import getIcon from "@/common/function/getIcon";
import compactWalletAddress from "@/common/function/compactWalletAddress";
import useResponsive from "@/hook/useResponsive";
import { Connection, clusterApiUrl } from "@solana/web3.js";
import { isEqualOrLatterDate as isLatterDate } from "@/common/function/isFutureDate";
import translation from "@/common/function/translation";
import remoteImage from "@/common/function/remoteImage";
import FormatterNumber from "./FormatterNumber.vue";
import formatterNumber from "@/common/function/formatterNumber";
import { toast } from "vue3-toastify";
import useToastMessage from "@/hook/useToastMessage";
import Congratulation from "@/common/class/Congratulation";
import formatter from "@/common/function/formatter";
import createKey from "@/common/function/createKey";
import createKeyByNumber from "@/common/function/createKeyByNumber";
import {
  callCrawler,
  rejectCrawler,
  getRpcConnection,
  generateTypeMemo,
} from "@/service/statictis";
import { useWallet } from "solana-wallets-vue";
import { SUBSCRIPTION_TYPE } from "@/common/const";
import fetchAllianceData from "@/common/function/fetchAllianceData";
import TwoTabAnalytic from "./TwotabAnalytic.vue";
import RoundButton from "./RoundButton.vue";
import isDark from "@/common/function/isDark";
import katex from "katex";
import ListItemCheck from "./ListItemCheck.vue";
import AnalyticComponent from "./AnalyticComponent.vue";
import fetchAnalyticChart from "@/common/function/fetchAnalyticChart";
import fetchBenefitData from "@/common/function/fetchBenefitData";
import IntroductionGroup from "./IntroductionGroup.vue";
import ConfirmWithdrawFinal from "./ConfirmWithdrawFinal.vue";
const cluster: any = process.env.VUE_APP_CLUSTER;
const token_fee: any = process.env.VUE_APP_TOKEN_FEE;
const rpc_mainnet: any = process.env.VUE_APP_RPC_MAINNET;
const selectedTimeRange = ref<string>("day");
const timeRange = [
  {
    value: "day",
    label: translation("1_day"),
  },
  {
    value: "week",
    label: translation("1_week"),
  },
  {
    value: "month",
    label: translation("1_month"),
  },
  {
    value: "year",
    label: translation("1_year"),
  },
];

let urlParams = new URLSearchParams(window.location.search);
const rpc_devnet: any =
  process.env.VUE_APP_RPC_DEVNET ||
  "https://devnet.helius-rpc.com/?api-key=09504665-044a-4169-978c-bcca7f758d02";

let connection: Connection;

if (cluster == "devnet") {
  connection = new Connection(rpc_devnet, {
    commitment: "confirmed",
    confirmTransactionInitialTimeout: 80000,
  });
} else {
  connection = new Connection(rpc_mainnet, {
    commitment: "confirmed",
    confirmTransactionInitialTimeout: 80000,
  });
}
const store = useStore();
const solana = useSolana();
const message = useToastMessage();
const myAffiliateGroup = ref<IAllianceGroup>();
const affiliateBonusInfo = ref<IAffiliateBonusInfo[]>([]);
const poolInfo = computed(() => store.state.poolInfo);
const loading = ref<boolean | undefined>(undefined);
const loadingX = ref<boolean | undefined>(undefined);
const myReferralData = ref<IMyReferral>();
const showQR = ref<boolean>(false);
const rewardTab = ref<"sol" | "solx">("sol");

const timeCountDown: string = process.env.VUE_APP_SYSTEM_COUNTDOWN_FUTURE;
const intervalCountdownFeture = ref<any>(null);
const statusCountdownFeture = ref<string>("");
const trigger = ref(0);
const onCountdownEnd = () => {
  console.error(">>>>>>>>>>>>>>>>>>>>>count end");
  trigger.value += 1;
};
const startCountdownFeture = () => {
  try {
    if (intervalCountdownFeture.value) {
      clearInterval(intervalCountdownFeture.value);
    }
    const targetTime = new Date(Number(timeCountDown) * 1000).getTime();
    console.log("🚀 ~ startCountdownFeture ~ targetTime:", targetTime);
    intervalCountdownFeture.value = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTime - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      //
      statusCountdownFeture.value =
        (days > 0 ? (days < 10 ? "0" + days : days) + ":" : "") +
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);

      if (distance < 0) {
        clearInterval(intervalCountdownFeture.value);
        statusCountdownFeture.value = null;
      }
    }, 1000);
  } catch (err) {
    console.log("🚀 ~ startCountdown ~ err:", err);
  }
};
//
function updateUrlParam(
  param: string,
  value: string,
  onDone?: () => any
): void {
  try {
    const url = new URL(window.location.href);
    const type = url.searchParams.get("type");
    if (type) {
      url.searchParams.set(param, value);
      window.history.replaceState({}, "", url.toString());
    }
    onDone && onDone();
  } catch (err) {
    console.error("updateUrlParam", err);
  }
}

onMounted(() => {
  // const isClaimingSol = localStorage.getItem("isClaimingSol") == "true";
  // const isClaimingSolx = localStorage.getItem("isClaimingSolx") == "true";
  // loading.value = isClaimingSol;
  // loadingX.value = isClaimingSolx;
  // if (isDeviceLaptop()) return;
  loading.value =
    urlParams.has("type") && urlParams.get("type") == "withdraw-bonus";
  loadingX.value =
    urlParams.has("type") && urlParams.get("type") == "withdraw-bonus";
});

const preventCallAPI = ref<number>(0);
const timeoutCallAPI = 8000;

const reducePreventAPI = () => {
  const reduceTimeout = setTimeout(() => {
    preventCallAPI.value > 0 && (preventCallAPI.value -= timeoutCallAPI);
    clearTimeout(reduceTimeout);
  }, timeoutCallAPI);
};

onMounted(() => {
  startCountdownFeture();
  emitter.on("fetchAff", () => {
    if (!preventCallAPI.value) {
      if (!loading.value && !loadingX.value) {
        store.commit("setFetching", "affiliate-date");
        preventCallAPI.value += timeoutCallAPI;
        fetchData();
        reducePreventAPI();
      }
    } else {
      const timeout = setTimeout(() => {
        if (!loading.value && !loadingX.value) {
          store.commit("setFetching", "affiliate-date");
          preventCallAPI.value += timeoutCallAPI;
          fetchData();
          reducePreventAPI();
        }
        clearTimeout(timeout);
      }, preventCallAPI.value);
    }
  });
});
onMounted(() => {
  emitter.on("claimBonus", (data) => {
    console.error(">>>>>>>>>>>>>>>>>>>>>>>>>>> has claimBonus", data);
    const { ...trans }: { transaction: any } = data[Object.keys(data)[0]];
    if (
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS] &&
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS].status == "processing"
    ) {
      message.loading(
        translation(
          "_Transaction_confirmed_please_wait_a_moment" +
            "_" +
            SUBSCRIPTION_TYPE.CLAIM_BONUS
        )
      );
      setTimeout(() => {
        message.warning(
          translation(
            "_Transaction_processing_please_wait_a_moment" +
              "_" +
              SUBSCRIPTION_TYPE.CLAIM_BONUS
          )
        );
      }, 1000);
      return;
    }
    if (
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS] &&
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS].status == "success"
    ) {
      updateUrlParam("type", "no-claim-bonus", () => {
        fetchData(() => {
          fetchAllianceData(false, true);
          // loading.value &&
        });
      });
      setTimeout(() => {
        message.success(
          translation(
            "_Transaction_success_you_can_check_at_history" +
              "_" +
              SUBSCRIPTION_TYPE.CLAIM_BONUS
          )
        );
      }, 3000);
      return;
    }
    if (
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS] &&
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS].status == "failed"
    ) {
      setTimeout(() => {
        message.error(
          translation(
            "_Transaction_error_you_can_check_at_history" +
              "_" +
              SUBSCRIPTION_TYPE.CLAIM_BONUS
          )
        );
      }, 3000);
      return;
    }
    if (
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE] &&
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE].status == "processing"
    ) {
      message.loading(
        translation(
          "_Transaction_confirmed_please_wait_a_moment" +
            "_" +
            SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE
        )
      );
      setTimeout(() => {
        message.warning(
          translation(
            "_Transaction_processing_please_wait_a_moment" +
              "_" +
              SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE
          )
        );
      }, 1000);
      return;
    }
    if (
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE] &&
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE].status == "success"
    ) {
      updateUrlParam("type", "no-claim-bonus-stake", () => {
        fetchData(() => {
          fetchAllianceData(false, true);
          // loadingX.value &&
        });
      });
      setTimeout(() => {
        message.success(
          translation(
            "_Transaction_success_you_can_check_at_history" +
              "_" +
              SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE
          )
        );
      }, 3000);
      return;
    }
    if (
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE] &&
      data[SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE].status == "failed"
    ) {
      setTimeout(() => {
        message.error(
          translation(
            "_Transaction_error_you_can_check_at_history" +
              "_" +
              SUBSCRIPTION_TYPE.CLAIM_BONUS_STAKE
          )
        );
      }, 3000);
      return;
    }
  });
});
onUnmounted(() => {
  emitter.off("fetchAff");
  emitter.off("claimBonus");
});
const emitter: Emitter<any> =
  getCurrentInstance()?.appContext.app.config.globalProperties.emitter;
watch(
  () => store.state.affiliateData,
  (newVal, oldVal) => {
    // Handle state change
    myAffiliateGroup.value = newVal;
  },
  { immediate: true }
);
watch(
  () => store.state.affiliateBonusInfo,
  (newVal, oldVal) => {
    // Handle state change
    myAffiliateGroup.value = newVal;
  },
  { immediate: true }
);

watch(
  () => myReferralData.value,
  () => {
    let remainTime =
      new Date(myReferralData.value?.solxReward?.unlock_time).getTime() -
      new Date().getTime();
    if (remainTime > 0) {
      const timeout = setInterval(() => {
        --remainTime;
        if (remainTime <= 0) {
          store.commit("setFetching", "affiliate-date");
          fetchData();
          clearInterval(timeout);
        }
      }, 1);
    }
  }
);

const showPopupShare = async () => {
  try {
    store.commit("setFetching", "affiliate-date");
    fetchData();
    if (myReferralData?.value?.status == false) {
      store.commit("setShowConditionSharePopup", true);
    } else {
      store.commit("setShowSharePopup", true);
    }
  } catch (err) {
    console.log("🚀 ~ showPopupShare ~ err:", err);
  }
};
const goMining = () => {
  store.commit("setPowering", "powering");
  store.commit("setShowConditionSharePopup", false);

  window.location.href = "#mining";
};
const fetchData = (onSuccess?: (data?: any) => any, isTheFist?: boolean) => {
  const {
    result,
    onResult,
    loading: fetchAPILoading,
    refetch,
  } = provideApolloClient(userClient)(() =>
    useQuery(getMyReferral, {
      fetchPolicy: "no-cache",
    })
  );
  onResult(() => {
    try {
      myReferralData.value = result.value?.referralData;
      store.commit("setReferralData", result.value?.referralData);
      console.error(">>>>>>>>>>>>rr data: ", result?.value);
      store.commit("setAffiliateData", result.value?.affiliateData?.data);
    } catch (error) {
      console.log(error);
    }
  });
  refetch();
  watch(
    () => result.value,
    () => {
      if (!isTheFist && result.value?.referralData) {
        onSuccess && onSuccess();
        if (loading.value) {
          loading.value = false;
          new Congratulation(
            document.getElementById("claim_sol_box") as HTMLElement
          );
          localStorage.setItem("status_c", "NCLMS"); //set is not claiming SOL to localStorage
        }
        if (loadingX.value) {
          loadingX.value = false;
          new Congratulation(
            document.getElementById("claim_solx_box") as HTMLElement
          );
        }
      }
    }
  );
};
watch(
  () => store.state.user,
  (newVal) => {
    if (newVal?.token) {
      store.commit("setReferralData", undefined);
      fetchData(() => undefined, true);
      fetchAnalyticChart();
      fetchBenefitData();
    } else {
      store.commit("setAllianceData", undefined);
      myAffiliateGroup.value = undefined;
      myReferralData.value = undefined;
      store.commit("setReferralData", undefined);
    }
  },
  {
    immediate: true,
  }
);

watch(
  () => store.state.affiliateBonusInfo,
  (newVal) => {
    affiliateBonusInfo.value = newVal;
  },
  {
    immediate: true,
  }
);

async function claimHandler() {
  try {
    const solana = useSolana();
    loading.value = true;
    localStorage.setItem("status_c", "CLMS"); //set is claiming SOL to localStorage
    const feeEst = (await solana.getEstTransferFee()) || 0;
    const feeAmount =
      feeEst +
      feeEst *
        (Number(
          poolInfo.value?.affSetting?.withdraw_aff_bonus_fee[0]
            ?.additional_percentage_fee
        ) /
          100);
    console.log("🚀 ~ claimHandler ~ feeAmount:", feeAmount);

    if (feeAmount <= 0) {
      message.error(translation("Fees_cannot_be_estimated"), {
        duration: 3000,
      });
      loading.value = false;
      return;
    }
    // callCrawlerHaveTransaction({
    //   method: methodCrawler.value.FEE_CLAIM_SOL_AFF_BONUS,
    // });
    if (isDeviceLaptop() && !store.state.isTelegram) {
      if (!solana.wallet?.connected) {
        message.error(`${translation("_please_connect_wallet")}`, {
          duration: 5000,
        });
      }
      const tx = await solana.stakingWebsite(
        poolInfo.value?.affSetting
          ?.wallets_affiliate_setting_wallet_bonus_miningTowallets?.public_key,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        generateTypeMemo(methodCrawler.value.FEE_CLAIM_SOL_AFF_BONUS),
        () => onUserCancel(),
        () => {
          loading.value = false;
          updateUrlParam("type", "no-claim-bonus");
          localStorage.setItem("isClaimingSol", "false");
        }
      );
      if (tx) {
        // message.loading(
        //   `${translation("_we_are_processing_Please_wait_a_moment")}`,
        //   {
        //     duration: 5000,
        //   }
        // );
        try {
          connection = getRpcConnection();
          connection.confirmTransaction(tx, "finalized");
        } catch (err) {
          console.error(err);
        }
      }
    } else {
      window.localStorage.setItem("transactionBlockchain", "withdraw-bonus");
      const url = await solana.signTransaction(
        poolInfo.value?.affSetting
          ?.wallets_affiliate_setting_wallet_bonus_miningTowallets?.public_key,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        false,
        `?amount=${feeAmount}}&type=claim-bonus`,
        generateTypeMemo(methodCrawler.value.FEE_CLAIM_SOL_AFF_BONUS)
      );
      if (!url) return;
      window.open(url?.toString());
    }
  } catch (error) {
    rejectCrawler();
    loading.value &&
      message.error(translation(`${"Claim_failed_please_try_again_later."}`));
    loading.value = false;
    updateUrlParam("type", "no-claim-bonus");
    localStorage.setItem("isClaimingSol", "false");
    console.error(">>>>>>>>>>claimHandler: ", error);
  }
}
const onUserCancelX = () => {
  loadingX.value = false;
  localStorage.setItem("isClaimingSolx", "false");
  updateUrlParam("type", "no-claim-bonus-stake");
};
const onUserCancel = () => {
  loading.value = false;
  updateUrlParam("type", "no-claim-bonus");
  localStorage.setItem("isClaimingSol", "false");
};

const methodCrawler = ref<any>({
  MINT: "mint",
  BOOST: "boost",
  SWAP: "swap",
  CLAIM_MINT: "fee-claim-solx-mint",
  CLAIM_BOOST_INTEREST: "fee-claim-solx-interest",
  CLAIM_BOOST_PRINCIPLE: "fee-claim-solx-principle",
  FEE_CLAIM_SOLX_AFF_BONUS: "fee-claim-solx-aff-bonus",
  FEE_CLAIM_SOL_AFF_BONUS: "fee-claim-sol-aff-bonus",
});

const callCrawlerHaveTransaction = (data: any) => {
  try {
    if (isDeviceLaptop()) {
      const { publicKey } = useWallet();

      if (publicKey.value) {
        callCrawler({
          address: publicKey.value.toString(),
          ...data,
        });
      }
      return;
    } else {
      if (store.state.sessionSolana?.public_key) {
        callCrawler({
          address: store.state.sessionSolana?.public_key.toString(),
          ...data,
        });
      }
      return;
    }
  } catch (err) {
    console.log("🚀 ~ callCrawlerHaveTransaction ~ err:", err);
  }
};
async function claimXHandler() {
  try {
    const solana = useSolana();
    loadingX.value = true;
    const feeEst = (await solana.getEstTransferFee()) || 0;
    let feeAmount =
      feeEst +
      feeEst *
        (Number(
          poolInfo.value?.affSetting?.withdraw_aff_bonus_fee[0]
            ?.additional_percentage_fee
        ) /
          100);
    console.log("🚀 ~ claimXHandler ~ feeAmount:", feeAmount);
    // feeAmount = +feeAmount.toFixed(9);
    if (feeAmount <= 0) {
      message.error(translation("Fees_cannot_be_estimated"), {
        duration: 3000,
      });
      loadingX.value = false;
      return;
    }
    // callCrawlerHaveTransaction({
    //   method: methodCrawler.value.FEE_CLAIM_SOLX_AFF_BONUS,
    // });
    if (isDeviceLaptop() && !store.state.isTelegram) {
      if (!solana.wallet?.connected) {
        message.error(`${translation("_please_connect_wallet")}`, {
          duration: 5000,
        });
      }
      console.log("logggg---", {
        public_key:
          poolInfo.value?.affSetting
            ?.wallets_affiliate_setting_wallet_bonus_stakingTowallets
            ?.public_key,
        amount: feeAmount,
        token_fee: token_fee || "So11111111111111111111111111111111111111112",
      });

      const tx = await solana.stakingWebsite(
        poolInfo.value?.affSetting
          ?.wallets_affiliate_setting_wallet_bonus_stakingTowallets?.public_key,
        feeAmount,
        token_fee || "So11111111111111111111111111111111111111112",
        generateTypeMemo(methodCrawler.value.FEE_CLAIM_SOLX_AFF_BONUS),
        () => onUserCancelX(),
        () => {
          loadingX.value = false;
          localStorage.setItem("isClaimingSolx", "false");
          updateUrlParam("type", "no-claim-bonus-stake");
        }
      );
      if (tx) {
        // message.loading(
        //   `${translation("_we_are_processing_Please_wait_a_moment")}`,
        //   {
        //     duration: 5000,
        //   }
        // );
        try {
          connection = getRpcConnection();

          connection.confirmTransaction(tx, "finalized");
        } catch (err) {
          console.error(err);
        }
        // const latestBlockHash = await connection.getLatestBlockhash();
        // await connection.confirmTransaction({
        //   blockhash: latestBlockHash.blockhash,
        //   lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
        //   signature: tx,
        // });
      }

      // emitter.emit("haveClaimBonus", {
      //   tx,
      //   fromWallet: store.state.solana?.wallet?.publicKey?.toString(),
      //   bonusType: "stake",
      //   onDone: () => {
      //     fetchData();
      //   },
      // });
      return;
    } else {
      window.localStorage.setItem("transactionBlockchain", "withdraw-bonus");
      window.localStorage.setItem("bonusType", "stake");

      const url = await solana.signTransaction(
        poolInfo.value?.affSetting
          ?.wallets_affiliate_setting_wallet_bonus_stakingTowallets?.public_key,
        feeAmount,
        token_fee,
        false,
        `?amount=${feeAmount}&type=claim-bonus-stake`,
        generateTypeMemo(methodCrawler.value.FEE_CLAIM_SOLX_AFF_BONUS)
      );
      if (!url) return;
      window.open(url?.toString());
      // return url;
    }
    // loadingX.value = false;
  } catch (error) {
    rejectCrawler();
    message.error(translation(`${"Claim_failed_please_try_again_later."}`));
    loadingX.value = false;
    localStorage.setItem("isClaimingSolx", "false");
    updateUrlParam("type", "no-claim-bonus-stake");
    console.error(error);
  }
}

onMounted(() => {
  // emitter.on("haveClaimBonus", (data) => {
  //   const dataRequest = {
  //     dataHash: data.tx,
  //     fromWallet: store.state.sessionSolana?.public_key ?? data.fromWallet,
  //     bonusType: data.bonusType || "buy",
  //   };
  //   const { onResult, result, refetch } = provideApolloClient(userClient)(() =>
  //     useQuery(claimReferral, {
  //       requestClaimBonusInput: dataRequest,
  //     })
  //   );
  //   onResult(() => {
  //     if (result.value) {
  //       if (result.value.claimBonus?.success) {
  //         data.onDone && data.onDone();
  //       } else {
  //         data.onDone && data.onDone();
  //         store.commit("setPlacement", "top-right");
  //         // message.error(
  //         //   translation(
  //         //     `${
  //         //       result.value.claimBonus?.result?.msg ??
  //         //       "Claim_failed_please_try_again_later."
  //         //     }`
  //         //   )
  //         // );
  //         console.error(result.value.claimBonus?.result?.msg);
  //       }
  //     }
  //   });
  // });
});
onUnmounted(() => {
  // emitter.off("haveClaimBonus");
});
const handleArray = (array: any[]) => {
  let result: any[] = [];
  const allBonusValue: any[] = [];
  array?.forEach((e: any) => {
    if (allBonusValue.indexOf(e.bonus) == -1) {
      allBonusValue.push(e.bonus);
    }
  });
  allBonusValue?.forEach((bonusValue: any) => {
    const temp: any[] = [];
    array?.forEach((e: any) => {
      if (e.bonus == bonusValue) {
        temp.push(e);
      }
    });
    result.push(temp);
  });
  result = result.map((e: any) => {
    if (e.length == 1) {
      return e[0];
    } else {
      return {
        level: `${e[0].level}-${e[e.length - 1].level}`,
        bonus: e[0].bonus,
      };
    }
  });
  return result;
};
</script>

<style scoped>
.dark .link_section * {
  color: var(--text-color-dark-mode);
}

.dark .social_title,
.dark .social_name {
  color: var(--text-color-dark-mode);
}

.link_section_popup_required {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.social_board {
  display: flex;
  margin: 16px 0;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 16px;
}

.social_item:hover {
  transform: translateY(-8px);
}

.social_item {
  display: flex;
  transition: 300ms;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 80px;
}

.social_name {
  color: var(--Neutral-01, #141416);
  text-align: center;
  font-family: "GT Walsheim";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 153.846% */
}

.link_g_des {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 24px;
}

.link_g {
  border-bottom: 1px solid var(--Neutral-07, #d1d1d1);
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.link_g input {
  flex: 1;
  min-width: 50px;
}

.social_title {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  text-transform: capitalize;
}

@media (max-width: 600px) {
  .detail_item {
    font-size: 17px !important;
  }

  .link_upgrade {
    font-size: 16px !important;
  }
}

.dark .detail_item {
  color: var(--text-color-dark-mode);
}

.detail_item {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.dark .reward_title {
  color: var(--text-color-dark-mode);
}

.reward_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 27px */
  text-transform: capitalize;
}

.dark .rank_value_group {
  color: white;
}

.dark .value {
  color: white;
}

.rank_value_group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.text_decoration {
  position: relative;
}

.text_decoration::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  border-top: 2px currentColor dashed;
  bottom: 3px;
}

.aff_upgrade_rank {
  border-bottom: 1px currentColor solid;
}

.dark .share_g {
  border: 2px solid var(--limit-color-dark-mode);
  background: var(--dark-black-light-more);
}

.share_g {
  display: flex;
  max-width: 644px !important;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: center;
  border-radius: 16px;
  border: 2px solid var(--app-questn-com-black, #000);
  background: var(--app-questn-com-guardsman-red, #fae1bc);
}

.share_g_new {
}

.home_link_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
}

.card_content {
  display: flex;
  flex-direction: column;
}

.receive_reward_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 24px 36px;
  gap: 24px;
  /* border-left: 2px solid #000; */
}

.receive_reward_block {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* border-radius: 16px; */
  /* border: 2px solid #000; */
  /* background: var(--Guardsman-Red-2, #f0ffb3); */
}

.title_g {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
}

.dark .title {
  color: var(--text-color-dark-mode);
}

.title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 33.6px */
  text-transform: capitalize;
}

.info_reward_g {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info_reward_g_new {
}

.dark .horizontal_divider {
  border-top: 2px var(--limit-color-dark-mode) solid;
}

.horizontal_divider {
  border-top: 2px black solid;
}

.amount_min_mint {
  color: var(--Neutral-01, #141416);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
}

.message_warning_share {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.line {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
  justify-content: space-between;
}

.dark .line_title {
  color: var(--text-color-dark-mode);
}

.line_title {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  line-height: 180%;
  /* 32.4px */
  font-weight: 400;
}

.dark .value {
  /* color: var(--text-color-dark-mode); */
}

.value {
  /* color: var(--app-questn-com-black, #000); */
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 32.4px */
  /* opacity: 0.8; */
  position: relative;
  top: -1px;
}

.total {
}

.dark .line_title_total,
.dark .value_total {
  color: var(--text-color-dark-mode);
}

.line_title_total {
  color: var(--Color-SOLX-Black, #000);
  font-family: "GT Walsheim";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 30px */
}

.value_total {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 50.4px */
}

.override_button {
  width: 100%;
  margin: 4px 0px;
}

.aff_info_section {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 36px;
}

.input_group {
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 16px;
  justify-content: space-between;
}

.input_group > input {
  border-bottom: 2px var(--app-questn-com-black) solid;
  flex: 1;
  width: 70%;
  outline: none;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  padding: 16px;
  font-weight: 500;
  line-height: 100%;
  /* 18px */
  opacity: 1;
}

#claim_sol_box {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: none;
}

#claim_solx_box {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  display: none;
}

input::placeholder {
  color: #777;
}

.reward_ref {
  color: var(--app-questn-com-black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  /* 33.6px */
  text-transform: capitalize;
}

.container_text_bottom_button {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #ff5153;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  text-wrap: wrap;
}

.text_bottom_button {
  color: #ff5153;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%;
  /* 28.8px */
  text-decoration-line: underline;
}

@media (max-width: 1279px) {
  .line_title_total {
    font-size: 18px;
  }

  .home_link_container {
    gap: 24px;
  }

  .card_content {
    flex-direction: column;
  }

  .receive_reward_section {
    flex-direction: column;
    border: 0;
    border-top: 2px solid #000;
    padding: 0;
    padding-top: 32px;
    padding-right: 16px;
    padding-bottom: 16px;

    padding-left: 16px;
  }

  .receive_reward_block {
  }

  .aff_info_section {
    padding: 0;
    padding-bottom: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-left: 16px;
  }

  .share_g {
    padding: 16px;
  }

  .title {
    font-size: 22px;
  }

  .line_title {
    font-size: 16px;
  }

  .value {
    font-size: 16px;
  }

  .value_total {
    font-size: 22px;
  }

  .input_group {
    gap: 8px;
  }

  .input_group > input {
    color: var(--app-questn-com-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 8px;
    line-height: 100%;
    /* 16px */
  }

  .g_link_title {
    white-space: nowrap;
    font-size: 16px;
  }

  .g_link_value {
    font-size: 18px;
  }
}

.link_upgrade {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
  text-decoration-line: underline;
}

.g_link_title {
  white-space: nowrap;
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 32.4px */
}

.g_link_value {
  color: var(--app-questn-com-black, #000);
  font-family: "GT Walsheim";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 180%;
  /* 36px */
}

.link_g_qr_des {
  color: var(--Neutral-03, #636363);
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  /* 28.8px */
}

.dark .override_card_content {
  background-color: var(--dark-black-light);
  border: 2px var(--limit-color-dark-mode) solid;
}

.dark .rank_navigate {
}

.dark .section_1 {
  border-bottom: 1px var(--limit-color-dark-mode) solid;
}

.section_1 {
  border-bottom: 1px black solid;
}
.dark .missing_message {
  color: #d1d1d1;
}
.missing_message {
  color: var(--Color-SOLX-Japanese-Laurel, #636363);
  font-family: "GT Walsheim";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
}

.dark .link_info_board {
  background-color: var(--dark-black-light);
}

.link_info_board {
  background-color: #ffd597;
}

.link_benefit_button {
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: "GT Walsheim";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 16.8px */
}

.next_level_condition {
  font-family: "GT Walsheim";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 19.2px */
}

.time_range_label {
  color: var(--app-questn-com-black, #000);
  leading-trim: both;
  text-edge: cap;
  font-family: "GT Walsheim";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 16.8px */
}
</style>
