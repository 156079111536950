<template>
  <div class="home_roadmap_container">
    <section-title :title="translation('roadmap_Roadmap')"> </section-title>
    <holder class="roadmap_row_scroll_able" ref="refScroll" v-scroll="onScroll">
      <div class="roadmap_row">
        <RoadmapCard
          v-for="(item, index) in dataRoadmap"
          :class="index % 2 === 0 ? 'margin_bottom_96' : 'margin_top_96'"
          :key="index"
          :image="domain + '/assets/' + item.image"
          :phase-name="item.phase"
          :title="item.header"
          :description="(item.body.slice(0, 1) as any).join(' ')"
          :detail="(item.body.slice(1) as any)"
        />
      </div>
    </holder>
    <div class="scroll_bar" @scroll="handleScroll" ref="refScrollBuffer">
      <div
        class="scroll_buffer"
        :style="{
          width: `${bufferScrollWidth}px`,
        }"
      >
        .
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SectionTitle from "./SectionTitle.vue";
import RoadmapCard from "./RoadmapCard.vue";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import { basicClient } from "@/graphql/client/basic-client";
import { getRoadmap } from "@/graphql/query/roadmapQuery";
import { IAffiliateBonusInfo, IRoadmap } from "@/common/type";
import { ref, watch } from "vue";
import store from "@/store";
import translation from "@/common/function/translation";
import { onMounted } from "vue";
import { useScroll, UseScrollReturn } from "@vueuse/core";
import { vScroll } from "@vueuse/components";
const domain = process.env.VUE_APP_DOMAIN_SERVER;
const dataRoadmap = ref<IRoadmap[]>([]);
const bufferScrollWidth = ref<number>(0);
const { onResult, result, refetch } = provideApolloClient(basicClient)(() =>
  useQuery(getRoadmap)
);
const isScrollingByMouse = ref<boolean>(false);
const preXMouse = ref<number>(0);
const scrollbarWidth = 250;
const refScroll = ref<any>();
const refScrollBuffer = ref<any>();
const { x, y } = useScroll(refScrollBuffer);
function onScroll(state: UseScrollReturn) {
  if (isScrollingByMouse.value) return;
  x.value = state.x.value;
}
const handleScroll = (e: Event) => {
  if (!isScrollingByMouse.value) return;
  const scrollLeftValue = (e?.target as HTMLElement)?.scrollLeft;
  refScroll.value.scrollLeft =
    scrollLeftValue * (refScroll.value?.clientWidth / scrollbarWidth);
};

onMounted(() => {
  refScrollBuffer.value.addEventListener("mousedown", () => {
    console.log("mousedown");
    isScrollingByMouse.value = true;
  });

  refScrollBuffer.value.addEventListener("mouseup", () => {
    console.log("mouseup");
    isScrollingByMouse.value = false;
  });
});

// const handleMouseMove = (e: any) => {
//   if (isScrollingByMouse.value) {
//     console.log(e.x);
//     // refScroll.value.scrollLeft += preXMouse.value - e.x;
//     refScroll.value.scrollTo({
//       left: refScroll.value.scrollLeft + preXMouse.value - e.x, // vị trí muốn cuộn đến theo chiều ngang (pixel)
//       behavior: "smooth", // kiểu cuộn (smooth hoặc auto)
//     });
//     // preXMouse.value = e.x;
//     // const timeout = setTimeout(() => {
//     //   clearTimeout(timeout);
//     // }, 10);
//     console.dir(refScroll.value.scrollLeft);
//   }
// };
// const handleMouseDown = (e: any) => {
//   isScrollingByMouse.value = true;
//   preXMouse.value = e.x;
// };
// const handleMouseUp = (e: any) => {
//   isScrollingByMouse.value = false;
// };

onResult(() => {
  try {
    const roadmap = JSON.stringify(result.value.roadmap?.result?.roadmap ?? []);
    const affiliateBonusRankInfo: IAffiliateBonusInfo[] =
      result.value.roadmap?.result?.commission ?? [];
    const affiliateBonusRankInfoX: IAffiliateBonusInfo[] =
      result.value.roadmap?.result?.commissionSolx ?? [];
    store.commit("setAffiliateBonusInfo", {
      sol: affiliateBonusRankInfo,
      solx: affiliateBonusRankInfoX,
    });
    dataRoadmap.value = JSON.parse(roadmap).map((item: any) => {
      item.body = item.body.split("\n").map((item: any) => item.trim());
      return item;
    });
    setTimeout(() => {
      bufferScrollWidth.value =
        (refScroll.value?.scrollWidth * scrollbarWidth) /
          refScroll.value?.clientWidth || 0;
    }, 300);
  } catch (error) {
    console.log("error", error);
  }
});
watch(dataRoadmap, (newVal, oldVal) => {
  console.log("🚀 ~ watch ~ newVal: >>>>>>>>>>>>>>>>>>>>>>>lll", newVal);
});
</script>

<style scoped>
@media (max-width: 1279px) {
  .scroll_bar {
    display: none !important;
  }
}
.scroll_bar {
  height: 12px;
  margin: 0 auto;
  width: v-bind(scrollbarWidth + "px");
  overflow-x: scroll;
}
.home_roadmap_container {
  display: flex;
  flex-direction: column;
  gap: 38px;
}
.roadmap_row_scroll_able {
  overflow-x: auto;
  overflow-y: visible;
  padding-bottom: 48px;
  padding-top: 72px;
  padding-right: 80px;
}
.roadmap_row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 50px;
  width: fit-content;
  /* cursor: grab; */
  /* user-select: none; */
  /* pointer-events: none; */
}
.margin_top_96 {
  margin-top: 96px;
}
.margin_bottom_96 {
  margin-bottom: 96px;
}

/* width */
.roadmap_row_scroll_able::-webkit-scrollbar {
  height: 0px;
}

.scroll_bar::-webkit-scrollbar {
  height: 10px !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100px;
  background: var(--app-questn-com-athens-gray, #d1d1d1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3ba2f;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--app-questn-com-saffron, #f3ba2f);
  cursor: pointer;
}
@media screen and (max-width: 1279px) {
  .home_roadmap_container {
    /* margin-bottom: -36px; */
    margin-bottom: 18px;
  }
  .roadmap_row {
    padding: 0;
    width: 100%;
  }
  .roadmap_row {
    flex-direction: column;
    padding-right: 0;
    gap: 24px;
  }
  .margin_top_96 {
    margin-top: 0;
  }
  .margin_bottom_96 {
    margin-bottom: 0;
  }
  .roadmap_row_scroll_able {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    overflow-x: visible;
    overflow-y: visible;
    padding-right: 0;
  }
}
</style>
