import { userClient } from "@/graphql/client/user-client";
import { getAllianceDataQuery } from "@/graphql/query/getAllianceDataQuery";
import store from "@/store";
import { provideApolloClient, useQuery } from "@vue/apollo-composable";
import filterByRank from "./filterByRank";
import { assignLevelAndBackgroundColor } from "./assignLevelAndBackgroundColor";
import { affDataFake } from "@/mock-data/affiliate";
import determineParent from "@/components/Affiliate/function/determineParent";
import { getUserById } from "./getUserById";
import createRankObject from "./createRankObject";
import { watch } from "vue";
import gql from "graphql-tag";

export const query = gql`
  query Benefit {
    getBenefit
  }
`;
export default function fetchBenefitData() {
  try {
    const { result, refetch, onResult } = provideApolloClient(userClient)(() =>
      useQuery(query)
    );
    onResult(() => {
      const res = result.value;
      console.error(">>>>>>>>>>>>>>>>>>>>>>Benefit data: ", res);
      const data = res?.getBenefit?.data;
      store.commit("setBenefitData", data);

      // if (allianceData) {

      //   determineParent(allianceData);
      //   store.commit("setChartTreeData", allianceData);
      //   store.commit("setAllianceData", {
      //     ...allianceData,
      //     show: true,
      //     parent: null,
      //     showingRank: -1,
      //   });
      // }
    });
  } catch (err) {
    console.error(err);
  }
}
